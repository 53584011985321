.color-youtube {
  color: $youtube-color;
}

.color-vimeo {
  color: $vimeo-color;
}

.color-dailymotion {
  color: $dailymotion-color;
}
