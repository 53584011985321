//
// validation.scss
// Extended from Bootstrap
//

//
// Bootstrap Overrides =====================================
//

.form-control.is-valid:focus, .form-control.is-invalid:focus {
  box-shadow: none;

  @if ($enable-shadows) {
    box-shadow: $input-focus-box-shadow;
  }
}
