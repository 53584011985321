swiper-container::part(button-prev) {
  left: 0;
  border-radius: 0 8px 8px 0;
  background-color: white;
  padding: 8px 12px 8px 8px;
  width: 10px;
  height: 30px;
  color: black;
}

swiper-container::part(button-next) {
  right: 0;
  border-radius: 8px 0 0 8px;
  background-color: white;
  padding: 8px 8px 8px 12px;
  width: 10px;
  height: 30px;
  color: black;
}
