.navbar-vertical.navbar-expand-md .navbar-brand-img {
  max-height: 5rem;
}

@include media-breakpoint-up(md) {
  .navbar-vertical.navbar-expand-md .navbar-brand {
    text-align: left;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
}


