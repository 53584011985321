@mixin tag-list{
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
  li {
    display: inline-block;
    vertical-align: top;
  }
}

@mixin tag {
  background: $color-info;
  border-radius: 20px;
  color: white;
  font-size: 14px;
  font-weight: 400;
  min-width: 65px;
  padding: 5px 12px;
  border: 0;

  &:hover {
    background-color: darken($color-info, 10%);
  }

  & > .remove, & > .close {
    margin-left: 5px;
    cursor: pointer;
    color: white;
    opacity: 1;
  }

  & > SPAN > SPAN {
    line-height: 16px;
  }

  &.small {
    font-size: 10px;
    color: white;
    border-radius: 0;
    padding: 3px 5px;
    margin-right: 3px;
    margin-bottom: 3px;
    display: inline-block;
    min-width: initial;

    &:first-child {
      margin-left: 0;
    }

    &.graduate {
      background-color: bs-var('primary');
    }

    &.internship {
      background-color: $color-yellow;
    }

    &.apprenticeship {
      background-color: $color-yellow;
    }
  }
}
