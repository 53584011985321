.ng-select {
  &.ng-select-single .ng-select-container {
    height: 40.5px;
  }

  .ng-select-container {
    border-color: $gray-400;
  }
}

.ng-dropdown-panel {
  z-index: 1056 !important;
}
