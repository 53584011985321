.dropzone {
  align-items: normal !important;
  height: auto !important;
  background: #fff !important;
  cursor: auto !important;
  color: inherit !important;
  border: 0 !important;
  border-radius: 0 !important;
  font-size: inherit !important;
  overflow-x: hidden !important;

  .dz-message {
    margin: 0;
  }

  .list-group-item {
    align-items: normal !important;
    border-radius: 0 !important;
    display: block !important;
    height: auto !important;
    justify-content: normal !important;
    margin: 0 !important;
    max-width: none !important;
    min-height: auto !important;
    min-width: auto !important;
    position: relative;
  }

  .list-group-flush > .list-group-item {

    padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x) !important;
    padding-left: 0 !important;
    padding-right: 0  !important;
  }
}
