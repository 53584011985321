.avatar-contain {
  object-fit: contain;
}

.letter-avatar {
  background-color: red;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
  color: white;
  text-transform: uppercase;
}
